import { type CurrencySymbols } from '@/features/currency/types/currency-types';
import { type Language } from '@/features/language/types/language-types';
import { getUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import sendHttpError from '@/libs/logger/requests/send-http-error';

export default async function updateLoggedUserLocalization(locale: Language['locale'], currency: CurrencySymbols['name']) {
  const bearerToken = getUserBearerCookie();

  const headers: HeadersInit = {};

  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
    headers.Locale = locale;
    headers.Currency = currency;
  }

  const url = `${process.env.NEXT_PUBLIC_API_URL}/v1/localizationsettings`;

  const updateLoggedUserLocalizationResponse = await fetch(url, {
    cache: 'no-store',
    headers,
    method: 'PUT',
  });

  if (!updateLoggedUserLocalizationResponse.ok) {
    sendHttpError({
      baseName: 'Update user localization settings',
      functionName: 'updateLoggedUserLocalization',
      method: 'PUT',
      requestHeaders: headers,
      statusCode: updateLoggedUserLocalizationResponse.status,
      url,
    });

    throw new Error('Failed to fetch data');
  }
}
