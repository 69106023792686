import {
  useEffect,
  useState,
} from 'react';
import {
  useIsClient,
  useIsMounted,
  useScreen,
} from 'usehooks-ts';

import APP_CONFIG from '@/configs/app-config';
import { getKeywordCookie } from '@/features/roomster-app/cookies/cookie-keyword';
import { getReferralCookie } from '@/features/roomster-app/cookies/cookie-referral';
import BREAKPOINTS from '@/shared/constants/breackpoints';

const baseAppFlyerLink = 'https://roomster.onelink.me/bzUF';
const baseAppFlyerQuery = 'af_xp=custom';

type DeepLinks = 'keyword' | 'referral' | 'path';

type LinkName =
  'mobileTopBanner' |
  'mobileLoginButton' |
  'mobileMenuListYourSpaceButton' |
  'mobileListingUserAvatar' |
  'mobileListingBookmark' |
  'mobileListingReport' |
  'mobileSearchAddListingButton' |
  'mobileListingMessageButton' |
  'mobileMessageButton' |
  'mobileListPlace' |
  'mobileFindPlace' |
  'mobileMailbox' |
  'mobileContactUs' |
  'mobileMegaphone' |
  'mobileBookmark' |
  'mobileSupport' |
  'mobileHelpCenter' |
  'mobileLoginPage' |
  'mobileMenuHelp' |
  'mobileSettings';

type MediaSource = 'mobile';

interface AppFlyerData {
  mobile: Record<LinkName, AppFlyerHref>
}

interface AppFlyerHref {
  pathname: string;
  query: string;
}

const deepLinks: Record<DeepLinks, string> = {
  keyword: 'deep_link_sub2',
  path: 'deep_link_value',
  referral: 'deep_link_sub1',
} as const;

function getAppFlyerLink(mediaSource: MediaSource, linkName: LinkName): AppFlyerHref {
  const referralCookie = getReferralCookie();
  const referralQuery = referralCookie ? `&${deepLinks.referral}=${encodeURIComponent(referralCookie)}` : `&${deepLinks.referral}=`;

  const keywordCookie = getKeywordCookie();
  const keywordQuery = keywordCookie ? `&${deepLinks.keyword}=${encodeURIComponent(keywordCookie)}` : `&${deepLinks.keyword}=`;

  const appFlyerLinks: AppFlyerData = {
    mobile: {
      mobileBookmark: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Bookmarks${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileContactUs: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Contact%20Us${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileFindPlace: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=HP%20Find-a-place%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileHelpCenter: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Help%20Center${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListingBookmark: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Listing%20Bookmark${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListingMessageButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Listing%20Message%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListingReport: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Listing%20Report${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListingUserAvatar: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Listing%20User%20Avatar${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListPlace: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=HP%20List-a-place%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileLoginButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Login%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileLoginPage: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Login%20Page%20Replacement${referralQuery}${keywordQuery}` },
      mobileMailbox: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Mailbox${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileMegaphone: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Megaphone${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileMenuHelp: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Menu%20Help%20Button${referralQuery}${keywordQuery}` },
      mobileMenuListYourSpaceButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Menu%20List-your-space%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileMessageButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Search%20Message%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileSearchAddListingButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Search%20Add-listing%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileSettings: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Support${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileSupport: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Support${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileTopBanner: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Top%20Banner${referralQuery}${keywordQuery}` },
    },
  } as const;

  return appFlyerLinks[mediaSource][linkName];
}

export default function useAppFlyerLink(mediaSource: MediaSource, linkName: LinkName, originalLink?: string): string {
  const isClient = useIsClient();
  const screen = useScreen();

  const isMobile = isClient && screen.width < BREAKPOINTS.MOBILE_SCREEN_WIDTH;

  const [appFlyerLink,
    setAppFlyerLink] = useState<string>('');
  const isMounted = useIsMounted();

  useEffect(() => {
    const link = getAppFlyerLink(mediaSource, linkName);

    const fullLink = `${link.pathname}?${link.query}`;

    if (APP_CONFIG.isMobileAuthEnabled) {
      setAppFlyerLink(originalLink ?? fullLink);
    } else {
      setAppFlyerLink(isMobile ? fullLink : originalLink ?? fullLink);
    }
  }, [mediaSource, linkName, isMounted, originalLink, isMobile]);

  return appFlyerLink;
}
