'use client';

import './select-search.scss';

import {
  components,
  type InputProps,
  type Props,
} from 'react-select';
import ReactSelect from 'react-select';

function SelectSearchInput<T, F extends boolean>(inputProps: InputProps<T, F>) {
  return <components.Input {...inputProps} aria-activedescendant="" />;
}

export default function SelectSearch<T, F extends boolean>(props: Props<T, F>) {
  return (
    <div className="select-wrapper">
      <ReactSelect
        {...props}
        classNamePrefix="selectSearch"
        components={{
          Input: SelectSearchInput,
          // eslint-disable-next-line react/destructuring-assignment
          ...props.components,
        }}
      />
    </div>
  );
}
