'use client';

import { BsGeoAltFill } from 'react-icons/bs';
import { type OptionProps } from 'react-select';
import { components } from 'react-select';

import { type Place } from '@/features/place/types/place-types';

import styles from './search-place-option-new.module.scss';

export default function SearchPlaceOptionNew(props: OptionProps<Place>) {
  const { children } = props;

  return (
    <components.Option {...props}>
      <div
        className={styles.option_link}
      >
        <div className={styles.option_icon}>
          <BsGeoAltFill />
        </div>
        <span>{children}</span>
      </div>
    </components.Option>
  );
}
