/* eslint-disable canonical/sort-keys */
// disable eslint sorting keys because it's easier to read when they grouped by value

import {
  type DefaultListingUrl,
  type ListingType,
  type ListingUrl,
} from '@/features/listing/types/listing-types';

export const LISTING_URL_PART_VARIANTS: Record<ListingUrl, ListingType> = {
  accommodations: 'haveshare',
  'flat-share': 'haveshare',
  'rooms-for-rent': 'haveshare',
  'rooms-to-let': 'haveshare',
  pg: 'haveshare',
  flatshares: 'haveshare',
  'rooms-to-rent': 'haveshare',
  haveshare: 'haveshare',
  apartments: 'haveapartment',
  'apartments-for-rent': 'haveapartment',
  'apartments-to-rent': 'haveapartment',
  haveapartment: 'haveapartment',
  flatmates: 'needroom',
  roommates: 'needroom',
  needroom: 'needroom',
  tenants: 'needapartment',
  needapartment: 'needapartment',
} as const;

export const DEFAULT_LISTING_PART_VARIANTS: Record<ListingType, DefaultListingUrl> = {
  haveshare: 'rooms-for-rent',
  haveapartment: 'apartments-for-rent',
  needroom: 'roommates',
  needapartment: 'tenants',
} as const;

export const LISTINGS_TYPES: Record<ListingType, number> = {
  haveshare: 2,
  haveapartment: 5,
  needroom: 1,
  needapartment: 4,
};

export const DEFAULT_LISTING_TYPE: ListingType = 'haveshare';

export const LISTING_VIEW_VARIANTS = {
  grid: 'grid',
  list: 'list',
} as const;
