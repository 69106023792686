'use client';

import { useParams } from 'next/navigation';
import { useLocale } from 'next-intl';
import {
  useEffect,
  useState,
} from 'react';
import { useIsMounted } from 'usehooks-ts';

import {
  DEFAULT_LISTING_PART_VARIANTS,
  LISTING_URL_PART_VARIANTS,
} from '@/features/listing/constants/listing-constants';
import { type ListingUrl } from '@/features/listing/types/listing-types';
import SearchPlaceSelect from '@/features/place/components/search-place-select/search-place-select';
import {
  getCookieCurrentPlace,
  setCookieCurrentPlace,
} from '@/features/place/cookies/cookie-current-place';
import {
  deleteCookieLastSearchedPlaces,
  setCookieLastSearchedPlaces,
} from '@/features/place/cookies/cookie-last-searched-places';
import {
  getCookieUserCoordinates,
  setCookieUserCoordinates,
} from '@/features/place/cookies/cookie-user-coordinates';
import getUserCoordinates from '@/features/place/requests/get-user-coordinates';
import { type Place } from '@/features/place/types/place-types';
import placeNameToUrl from '@/features/place/utils/place-name-to-url';
import { useRouter } from '@/i18n/routing';

interface SearchPlaceProps {
  lastSearchedPlaces: Place[] | null;
  currentPlace: Place | null;
  isNeedMoveForwardButton?: boolean;
  moveForwardButtonCallback?: () => void;
  isMainPage?: boolean;
}

export default function SearchPlace(props: SearchPlaceProps) {
  const {
    currentPlace,
    isMainPage,
    isNeedMoveForwardButton,
    lastSearchedPlaces,
    moveForwardButtonCallback,
  } = props;

  const router = useRouter();
  const locale = useLocale();
  const params = useParams();
  const isMounted = useIsMounted();

  const [placeOptions,
    setPlaceOptions] = useState<Place[]>(lastSearchedPlaces ?? []);

  const paramsListingType = params.listingType ?? 'rooms-for-rent';
  const currentListingType = LISTING_URL_PART_VARIANTS[paramsListingType as ListingUrl];
  const listingTypeSlug = DEFAULT_LISTING_PART_VARIANTS[currentListingType];

  useEffect(() => {
    const userCoordinatesCookie = getCookieUserCoordinates();
    const currentPlaceCookie = getCookieCurrentPlace();

    if (!userCoordinatesCookie) {
      getUserCoordinates()
        .then((userCurrentCoords) => {
          if (userCurrentCoords) {
            setCookieUserCoordinates(userCurrentCoords);
          }
        })
        .catch(() => null);
    }

    if (currentPlace?.fullName && (currentPlaceCookie?.fullName !== currentPlace.fullName)) {
      setCookieCurrentPlace({
        ...currentPlace,
        listingTypeUrl: listingTypeSlug,
      });
      deleteCookieLastSearchedPlaces();
      setPlaceOptions([]);
    }
  }, [currentPlace, isMounted, listingTypeSlug]);

  const handleShowNearMeClick = (userCurrentPlace: Place) => {
    const placeLink = placeNameToUrl(userCurrentPlace.fullName);

    setCookieCurrentPlace({
      ...userCurrentPlace,
      listingTypeUrl: listingTypeSlug,
    });

    router.push(`/${listingTypeSlug}/${placeLink}`, { locale });
  };

  const onChangeSelect = (place: Place | null, foundPlaces: Place[] | null) => {
    if (place) {
      const placeLink = placeNameToUrl(place.fullName);

      setCookieCurrentPlace({
        ...place,
        listingTypeUrl: listingTypeSlug,
      });

      if (foundPlaces) {
        setCookieLastSearchedPlaces(foundPlaces);
      }

      if (moveForwardButtonCallback) {
        moveForwardButtonCallback();
      } else {
        router.push(`/${listingTypeSlug}/${placeLink}`, { locale });
      }
    }
  };

  return (
    <SearchPlaceSelect
      onShowNearMeClick={handleShowNearMeClick}
      onChangeSelect={onChangeSelect}
      options={placeOptions}
      isNeedMoveForwardButton={isNeedMoveForwardButton}
      isMainPage={isMainPage}
      currentPlace={currentPlace}
      moveForwardButtonCallback={moveForwardButtonCallback}
    />
  );
}
