import cx from 'classix';
import Image from 'next/image';

import { Link } from '@/i18n/routing';
import { DOWNLOAD_APP_LINKS } from '@/shared/constants/links-constants';

import styles from './app-download.module.scss';

export default function AppDownload() {
  return (
    <div className={styles.container}>
      <Link className={styles.link} href={DOWNLOAD_APP_LINKS.appStore} rel="nofollow" target="_blank">
        <Image src="/images/services/applestore.svg" alt="apple store" width={25} height={25} />
      </Link>

      <Link className={cx(styles.link, styles.google_icon)} href={DOWNLOAD_APP_LINKS.googlePlay} rel="nofollow" target="_blank">
        <Image src="/images/services/playmarket.svg" alt="apple store" width={25} height={25} />
      </Link>
    </div>
  );
}
