import {
  type CurrenciesList,
  type Currency,
} from '@/features/currency/types/currency-types';

export const DEFAULT_CURRENCY: Currency = {
  name: 'USD',
  postfix: 'USD',
  prefix: '$',
  rate: 1,
};

export const DEFAULT_CURRENCISE_LIST: CurrenciesList = { [DEFAULT_CURRENCY.name]: DEFAULT_CURRENCY };
