import {
  getCookie,
  setCookie,
} from 'cookies-next/client';

import { SECONDS } from '@/shared/constants/time-constants';

const cookieName = 'hideAppBanner';

export function setCookieAppBannerHide(isHideAppBanner: boolean): void {
  setCookie(cookieName, isHideAppBanner, {
    maxAge: SECONDS.DAY,
    path: '/',
  });
}

export function getCookieAppBannerHide(): boolean {
  const cookie = getCookie(cookieName);

  return cookie === 'true';
}
