import cx from 'classix';
import { useTranslations } from 'next-intl';
import {
  FaBullhorn,
  FaEnvelope,
} from 'react-icons/fa6';

import { type MessagesCounters } from '@/features/user/types/message-counters-types';
import { MessagesType } from '@/features/user/types/message-counters-types';
import { ROUTER_LINKS } from '@/shared/constants/links-constants';
import Button from '@/ui/button/button';
import NotificationsIndicator from '@/ui/notification-indicator/notifications-indicator';

import styles from './header-logined-links.module.scss';

interface HeaderLoginedLinksProps {
  messagesCounters: MessagesCounters | null;
  helpdeskNewNotificationsCount: number | null;
}

export default function HeaderLoginedLinks(props: HeaderLoginedLinksProps) {
  const {
    helpdeskNewNotificationsCount,
    messagesCounters,
  } = props;

  const translationsLinks = useTranslations('global.links');
  const inboxMessages = messagesCounters?.[MessagesType.Inbox];

  return (
    <ul className={styles.headerLoginedLinks}>
      <li>
        { /* TODO: remove prefetch={false} when routes will be implemented in this app  */ }
        <Button
          element="link"
          variant="ghost"
          size="lg"
          prefetch={false}
          href={ROUTER_LINKS.megaphone}
        >
          {translationsLinks('megaphone')}
        </Button>
      </li>

      <li className={styles.headerLoginedLinks_item}>
        {!!inboxMessages && inboxMessages.countDirectNew > 0 && (
          <div
            className={cx(
              styles.headerLoginedLinks_messageIndicator,
              styles.headerLoginedLinks_messageIndicatorRight,
            )}
          >
            <NotificationsIndicator
              icon={FaEnvelope}
              text={inboxMessages.countDirectNew > 999 ? '999+' : inboxMessages.countDirectNew}
            />
          </div>
        )}

        {!!inboxMessages && inboxMessages.countMegaphoneNew > 0 && (
        <div
          className={cx(
            styles.headerLoginedLinks_messageIndicator,
            inboxMessages.countDirectNew
              ? styles.headerLoginedLinks_messageIndicatorLeft
              : styles.headerLoginedLinks_messageIndicatorRight,
          )}
        >
          <NotificationsIndicator
            icon={FaBullhorn}
            color="blue"
            text={inboxMessages.countMegaphoneNew > 999 ? '999+' : inboxMessages.countMegaphoneNew}
          />
        </div>
        )}

        { /* TODO: remove prefetch={false} when routes will be implemented in this app  */}
        <Button
          element="link"
          variant="ghost"
          size="lg"
          prefetch={false}
          href={ROUTER_LINKS.messages}
        >
          {translationsLinks('messages')}
        </Button>
      </li>

      <li className={styles.headerLoginedLinks_item}>
        {!!helpdeskNewNotificationsCount && helpdeskNewNotificationsCount > 0 && (
          <div
            className={cx(
              styles.headerLoginedLinks_messageIndicator,
              styles.headerLoginedLinks_messageIndicatorHelpdesk,
            )}
          >
            <NotificationsIndicator
              color="orange"
              text={helpdeskNewNotificationsCount > 99 ? '99+' : helpdeskNewNotificationsCount}
            />
          </div>
        )}

        { /* TODO: remove prefetch={false} when routes will be implemented in this app  */ }
        <Button
          element="link"
          variant="ghost"
          size="lg"
          prefetch={false}
          href={ROUTER_LINKS.help}
        >
          {translationsLinks('help')}
        </Button>
      </li>
    </ul>
  );
}
