import {
  getCookie,
  setCookie,
} from 'cookies-next/client';

import { type Coordinates } from '@/features/place/types/place-types';
import { DEFAULT_COOKIE_SETTING } from '@/shared/constants/cookie-constants';
import isValidLatitude from '@/shared/utils/is-valid-latitude';
import isValidLongitude from '@/shared/utils/is-valid-longitude';

const cookieName = 'userCoordinates';

export function getCookieUserCoordinates(): Coordinates | null {
  const cookie = getCookie(cookieName);

  if (cookie) {
    const userCoordinates = JSON.parse(cookie) as Coordinates;

    return isValidLatitude(userCoordinates.latitude) && isValidLongitude(userCoordinates.longitude) ? userCoordinates : null;
  }

  return null;
}

export function setCookieUserCoordinates(coordinates: Coordinates) {
  const durationOneDay = 60 * 60 * 24;

  setCookie(cookieName, coordinates, {
    ...DEFAULT_COOKIE_SETTING,
    maxAge: durationOneDay,
  });
}
