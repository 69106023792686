import { type ListingType } from '@/features/listing/types/listing-types';
import { type Gender } from '@/features/user/types/user-types';

export type ListingsFilterName = 'listingsType' | 'listingsBudget' | 'listingsDetailed' | 'listingsSort' | 'listingsView';

type ListingFilterType = 'radio' | 'checkbox' | 'range';

export type FiltersColumns = '1' | '2' | '3' | '4';

export interface ListingsFilterBase {
  filterType: ListingFilterType;
  filterTitle: string;
  columns?: FiltersColumns;
}

export interface ListingsCheckboxFilterPath<OptionValue, PathValue, PathToken> {
  filterType: 'radio';
  isOptionsTranslated?: boolean;
  name: FiltersNames;
  changingHrefPart: {
    type: 'path';
    token: PathToken;
  };
  options: {
    pathValue: PathValue;
    value: OptionValue;
  }[];
}

interface ListingsRangeFilterOption {
  ariaLabel: string;
  name: FiltersNames;
  value: number;
  defaultValue: number;
}

export interface ListingsRangeFilter extends ListingsFilterBase {
  options: {
    max: ListingsRangeFilterOption;
    min: ListingsRangeFilterOption;
  };
  step: number;
  filterType: 'range';
}

export type SexFilters = 'co-ed' | 'everyone' | Gender;

export type Zodiac = 'aries' | 'taurus' | 'gemini' | 'cancer' | 'leo' | 'virgo' | 'libra' | 'scorpio' | 'sagittarius' | 'capricorn' | 'aquarius' | 'pisces';

export type Pet = 'cats' | 'dogs' | 'fish' | 'birds' | 'reptiles' | 'small-pets';

export interface ListingsFilterToggle<Label> extends ListingsFilterBase {
  name: FiltersNames;
  filterType: 'radio' | 'checkbox';
  defaultValue?: string;
  isOptionsTranslated?: boolean,
  optionsTranslatedKey?: string,
  optionsVariant?: 'standard' | 'button';
  options: {
    value: string;
    label: Label;
  }[];
}

// export enum LinkPart {
//   Pathname = 'pathname',
//   SearchParams = 'searchParams',
// }

export enum GeoBboxNames {
  LatitudeSouthWest = 'search_params.geo.lat_sw',
  LongitudeSouthWest = 'search_params.geo.lng_sw',
  LatitudeNorthEast = 'search_params.geo.lat_ne',
  LongitudeNorthEast = 'search_params.geo.lng_ne',
}

export enum FiltersNames {
  Sex = 'search_params.sex',
  HouseholdSex = 'search_params.household_sex',
  Zodiac = 'search_params.zodiac',
  PetsOwn = 'search_params.my_pets',
  PetsPreferred = 'search_params.pets_preference',
  Bedrooms = 'search_params.bedrooms',
  Bathrooms = 'search_params.bathrooms',
  Amenities = 'search_params.amenities',
  GeoRadiusScale = 'search_params.geo.radius_scale',
  AgeMax = 'search_params.age.max',
  AgeMin = 'search_params.age.min',
  BudgetMax = 'search_params.budget.max',
  BudgetMin = 'search_params.budget.min',
  ListingType = 'search_params.service_type',
  Sort = 'search_params.sort',
}

export enum PaginationNames {
  PageNumber = 'search_params.page_number',
  PageSize = 'search_params.page_size',
}

export enum ExtraFieldsNames {
  IncludeAvgRent = 'search_params.include_avg_rent',
  IncludeTotalCount = 'search_params.include_total_count',
}

export enum MapParamsNames {
  MapData = 'search_params.map_data_only',
}

export interface FiltersDefault {
  [FiltersNames.ListingType]: ListingType;
  [FiltersNames.Sort]: string;
  [FiltersNames.GeoRadiusScale]: string;
  [PaginationNames.PageSize]: string;
}

export interface FiltersParams extends FiltersDefault {
  [FiltersNames.Sex]?: Exclude<SexFilters, 'co-ed'>;
  [FiltersNames.HouseholdSex]?: SexFilters;
  [FiltersNames.Zodiac]?: Zodiac;
  [FiltersNames.PetsOwn]?: Pet;
  [FiltersNames.PetsPreferred]?: Pet;
  [FiltersNames.Bedrooms]?: string;
  [FiltersNames.Bathrooms]?: string;
  [FiltersNames.Amenities]?: string;
}
