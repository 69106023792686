import { type OptionsType } from 'cookies-next';
import { setCookie } from 'cookies-next';
import { getCookie } from 'cookies-next/client';

import sendHttpError from '@/libs/logger/requests/send-http-error';

const cookieName = 'compaign_referrer_id';
const defaultCookieValue = '00001ad8j';
const compareSeconds = 30;
const durationThirtyDays = 60 * 60 * 24 * 30;

export async function saveReferralCookie(referralId: string, referralTimestamp: number, options?: OptionsType) {
  const url = `https://api.roomster.com/v1/AffiliateInfo/?campaign=${referralId}`;
  const response = await fetch(url, { cache: 'no-store' });

  if (!response.ok) {
    sendHttpError({
      baseName: 'Get affiliate info for referral',
      functionName: 'saveReferralCookie',
      method: 'GET',
      statusCode: response.status,
      url,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = await response.json();

  const secondsDiff = Math.floor(Date.now() / 1000) - referralTimestamp;
  const isMoreThenThirtySeconds = secondsDiff > compareSeconds;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  await (data.is_external && (isMoreThenThirtySeconds || !referralTimestamp) ? setCookie(cookieName, defaultCookieValue, {
    maxAge: durationThirtyDays,
    path: '/',
    ...options,
  }) : setCookie(cookieName, referralId, {
    maxAge: durationThirtyDays,
    path: '/',
    ...options,
  }));
}

export async function saveSearchEngineReferralCookie(referralId: string, options?: OptionsType) {
  await setCookie(cookieName, referralId, {
    maxAge: durationThirtyDays,
    path: '/',
    ...options,
  });
}

export function getReferralCookie(): string | false {
  const cookie = getCookie(cookieName);

  return cookie ?? false;
}
