import cx from 'classix';
import { type ReactNode } from 'react';

import styles from './badge.module.scss';

interface BadgeProps {
  children: ReactNode;
  color?: 'yellow' | 'green' | 'red' | 'orange' | 'gray';
}

export default function Badge(props: BadgeProps) {
  const {
    children,
    color = 'yellow',
  } = props;

  return (
    <div className={cx(styles.badge, styles[`badge__${color}`])}>
      {children}
    </div>
  );
}
