'use client';

import { deleteCookie } from 'cookies-next/client';
import { useSetAtom } from 'jotai';
import { useParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import {
  FaAngleRight,
  FaArrowRightFromBracket,
  FaBullhorn,
  FaEnvelope,
  FaGear,
  FaHeadset,
  FaQuestion,
  FaRegHeart,
  FaRegMessage,
  FaSistrix,
  FaUser,
} from 'react-icons/fa6';
import {
  MdOutlineGridView,
  MdOutlineHelpOutline,
} from 'react-icons/md';

import { analyticSetUser } from '@/features/analytic/enteties/analytic';
import { type UserListingsData } from '@/features/listing/types/user-listings-types';
import useAppFlyerLink from '@/features/roomster-app/hooks/use-app-flyer-link';
import { loggedUserAtom } from '@/features/user/atoms/logged-user-atom';
import { deleteUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import {
  type MessagesCounters,
  MessagesType,
} from '@/features/user/types/message-counters-types';
import { type LoggedUser } from '@/features/user/types/user-types';
import {
  Link,
  useRouter,
} from '@/i18n/routing';
import { deleteCookieUUID } from '@/layout/cookie-accept-popup/cookies/cookie-uuid';
import styles from '@/layout/menu/menu.module.scss';
import {
  EXTERNAL_LINKS,
  ROUTER_LINKS,
} from '@/shared/constants/links-constants';
import Badge from '@/ui/badge/badge';
import NotificationsIndicator from '@/ui/notification-indicator/notifications-indicator';

/* eslint-disable canonical/prefer-react-lazy */

interface NavigationProps {
  user: LoggedUser | null;
  messagesCounters: MessagesCounters | null;
  helpdeskNewNotificationsCount: number | null;
  userListingsData: UserListingsData | null;
  closeMenu: () => void;
  searchLink: string;
  isFirstListing?: boolean;
}

export default function Navigation(props: NavigationProps) {
  const {
    closeMenu,
    helpdeskNewNotificationsCount,
    isFirstListing,
    messagesCounters,
    searchLink,
    user,
    userListingsData,
  } = props;

  const setLoggedUserData = useSetAtom(loggedUserAtom);

  const router = useRouter();
  const params = useParams();
  const isListingsListPage = !!params.locale && !!params.listingType && params.location && params.location.length > 0;

  const translationsLinks = useTranslations('global.links');
  const translationsActions = useTranslations('global.actions');
  const translationsMenu = useTranslations('layout.menu');
  const placeAppFlyerLink = useAppFlyerLink('mobile', 'mobileMenuListYourSpaceButton', ROUTER_LINKS.phoneAuth);
  const helpAppFlyerLink = useAppFlyerLink('mobile', 'mobileMenuHelp', ROUTER_LINKS.phoneAuth);

  const inboxMessages = messagesCounters?.[MessagesType.Inbox];

  const handleLogout = () => {
    new Promise((resolve) => {
      resolve((() => {
        analyticSetUser(null);
        deleteUserBearerCookie();
        deleteCookieUUID();
        deleteCookie('loggedUserLocalizationSettings');

        return true;
      })());
    })
      .then(() => {
        setLoggedUserData(null);
        router.replace(ROUTER_LINKS.home);
      })
      .catch(() => null);
  };

  return (
    <ul className={styles.nav}>
      {user ? (
        <>
          {!isFirstListing && (
            <>
              <li>
                {isListingsListPage ? (
                  <button
                    className={styles.nav_link}
                    onClick={closeMenu}
                  >
                    <FaSistrix className={styles.nav_icon} />
                    {translationsLinks('search')}
                    <FaAngleRight className={styles.nav_arrowIcon} />
                  </button>
                ) : (
                  <Link
                    className={styles.nav_link}
                    href={searchLink}
                  >
                    <FaSistrix className={styles.nav_icon} />
                    {translationsLinks('search')}
                    <FaAngleRight className={styles.nav_arrowIcon} />
                  </Link>
                )}
              </li>

              <li>
                <Link
                  className={styles.nav_link}
                  href={ROUTER_LINKS.bookmarks}
                >
                  <FaRegHeart className={styles.nav_icon} />
                  {translationsLinks('bookmarks')}
                  <FaAngleRight className={styles.nav_arrowIcon} />
                </Link>
              </li>

              <div className={styles.nav_divider} />

              <li>
                <Link
                  className={styles.nav_link}
                  href={ROUTER_LINKS.accountProfile}
                >
                  <FaUser className={styles.nav_icon} />
                  {translationsLinks('my-profile')}
                  {(user.missingFields.length > 0) && (
                    <div className={styles.nav_indicatorsArea}>
                      <div className={styles.nav_completeBadge}>
                        <Badge>{translationsMenu('complete-profile')}</Badge>
                      </div>
                    </div>
                  )}
                  <FaAngleRight className={styles.nav_arrowIcon} />
                </Link>
              </li>

              <li>
                <Link
                  className={styles.nav_link}
                  href={ROUTER_LINKS.accountListings}
                >
                  <MdOutlineGridView className={styles.nav_icon} />
                  {translationsLinks('my-listings')}
                  {userListingsData && userListingsData.count > 0 && (`(${String(userListingsData.count)})`)}
                  {userListingsData?.isDataMissing && (
                    <div className={styles.nav_indicatorsArea}>
                      <div className={styles.nav_completeBadge}>
                        <Badge>{translationsMenu('complete-listings')}</Badge>
                      </div>
                    </div>
                  )}
                  <FaAngleRight className={styles.nav_arrowIcon} />
                </Link>
              </li>

              <div className={styles.nav_divider} />

              <li>
                <Link
                  className={styles.nav_link}
                  href={ROUTER_LINKS.messages}
                >
                  <FaRegMessage className={styles.nav_icon} />
                  {translationsLinks('messages')}
                  <div className={styles.nav_indicatorsArea}>
                    {!!inboxMessages && inboxMessages.countDirectNew > 0 && (
                      <div className={styles.nav_messageIndicator}>
                        <NotificationsIndicator
                          icon={FaEnvelope}
                          text={inboxMessages.countDirectNew > 999 ? '999+' : inboxMessages.countDirectNew}
                        />
                      </div>
                    )}

                    {!!inboxMessages && inboxMessages.countMegaphoneNew > 0 && (
                      <div className={styles.nav_messageIndicator}>
                        <NotificationsIndicator
                          icon={FaBullhorn}
                          color="blue"
                          text={inboxMessages.countMegaphoneNew > 999 ? '999+' : inboxMessages.countMegaphoneNew}
                        />
                      </div>
                    )}
                  </div>

                  <FaAngleRight className={styles.nav_arrowIcon} />
                </Link>
              </li>

              <li>
                <Link
                  className={styles.nav_link}
                  href={ROUTER_LINKS.megaphone}
                >
                  <FaBullhorn className={styles.nav_icon} />
                  {translationsLinks('megaphone')}
                  <FaAngleRight className={styles.nav_arrowIcon} />
                </Link>
              </li>

              <div className={styles.nav_divider} />

              <li>
                <Link
                  className={styles.nav_link}
                  href={ROUTER_LINKS.settings}
                >
                  <FaGear className={styles.nav_icon} />
                  {translationsLinks('settings')}
                  <FaAngleRight className={styles.nav_arrowIcon} />
                </Link>
              </li>

              <li>
                <Link
                  className={styles.nav_link}
                  href={EXTERNAL_LINKS.faq}
                >
                  <FaQuestion className={styles.nav_icon} />
                  {translationsLinks('faq')}
                  <FaAngleRight className={styles.nav_arrowIcon} />
                </Link>
              </li>

              <li className={styles.nav_item}>
                {!!helpdeskNewNotificationsCount && helpdeskNewNotificationsCount > 0 && (
                  <div
                    className={styles.nav_messageIndicatorHelpdesk}
                  >
                    <NotificationsIndicator
                      color="orange"
                      text={helpdeskNewNotificationsCount > 99 ? '99+' : helpdeskNewNotificationsCount}
                    />
                  </div>
                )}

                <Link
                  className={styles.nav_link}
                  href={ROUTER_LINKS.support}
                >
                  <FaHeadset className={styles.nav_icon} />
                  {translationsLinks('support')}
                  <FaAngleRight className={styles.nav_arrowIcon} />
                </Link>
              </li>
            </>
          )}

          <div className={styles.nav_divider} />

          <li className={styles.nav_logout}>
            <button
              className={styles.nav_link}
              onClick={handleLogout}
            >
              <FaArrowRightFromBracket className={styles.nav_icon} />
              {translationsActions('logout')}
            </button>
          </li>
        </>
      ) : (
        <>
          <li>
            <Link
              className={styles.nav_link}
              href={placeAppFlyerLink}
              passHref
              target="_blank"
            >
              <MdOutlineGridView className={styles.nav_icon} />
              {translationsLinks('list-space')}
            </Link>
          </li>

          <li>
            <Link
              className={styles.nav_link}
              href={helpAppFlyerLink}
            >
              <MdOutlineHelpOutline className={styles.nav_icon} />
              {translationsLinks('help')}
            </Link>
          </li>
        </>
      )}
    </ul>
  );
}
