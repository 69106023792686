'use client';

import cx from 'classix';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import {
  useEffect,
  useState,
} from 'react';
import { FaXmark } from 'react-icons/fa6';
import { useIsMounted } from 'usehooks-ts';

import {
  getCookieAppBannerHide,
  setCookieAppBannerHide,
} from '@/features/roomster-app/cookies/cookie-app-banner-hide';
import useAppFlyerLink from '@/features/roomster-app/hooks/use-app-flyer-link';
import Button from '@/ui/button/button';

import styles from './app-banner.module.scss';

export default function AppBanner() {
  const translationsAppBanner = useTranslations('roomster-app.app-banner');
  const appFlyerLink = useAppFlyerLink('mobile', 'mobileTopBanner');
  const isMounted = useIsMounted();

  const [isBannerClosed,
    setIsBannerClosed] = useState<boolean>(true);

  const handleCloseBanner = () => {
    setIsBannerClosed(true);
    setCookieAppBannerHide(true);
  };

  const handleGetAppClick = () => {
    handleCloseBanner();
  };

  useEffect(() => {
    setIsBannerClosed(getCookieAppBannerHide());
  }, [isMounted]);

  return (
    <div id="app-banner" className={cx(styles.appBanner, isBannerClosed && styles.appBanner__isClosed)}>
      <div className={styles.appBanner_container}>
        <div className={styles.appBanner_content}>
          <button
            className={styles.appBanner_close}
            onClick={handleCloseBanner}
            aria-label="close"
          >
            <FaXmark className={styles.appBanner_closeIcon} />
          </button>

          <div className={styles.appBanner_info}>
            <div
              className={styles.banner_text}
              dangerouslySetInnerHTML={{ __html: translationsAppBanner.raw('text') as string }}
            />

            <Button
              onClick={handleGetAppClick}
              href={appFlyerLink}
              element="nativeLink"
              variant="filled"
              // size="lg"
              shade="light"
              target="_blank"
              // fontSize="s"
            >
              {translationsAppBanner('button')}
            </Button>
          </div>

          <div className={styles.appBanner_imageContainer}>
            <Image
              loading="eager"
              className={styles.appBanner_image}
              src="/images/roomster-app/app-phones.png"
              alt="roomster app"
              fill
            />
          </div>
        </div>
      </div>
    </div>
  );
}
