'use client';

import cx from 'classix';
import { useAtomValue } from 'jotai/index';
import { useHydrateAtoms } from 'jotai/utils';
import Image from 'next/image';
import { useTranslations } from 'next-intl';

import CurrencySelect from '@/features/currency/components/currency-picker/currency-select/currency-select';
import {
  type CurrenciesList,
  type Currency,
} from '@/features/currency/types/currency-types';
import LanguageSelect from '@/features/language/components/language-picker/language-select/language-select';
import { type Language } from '@/features/language/types/language-types';
import {
  DEFAULT_LISTING_PART_VARIANTS,
  LISTING_URL_PART_VARIANTS,
} from '@/features/listing/constants/listing-constants';
import { type ListingType } from '@/features/listing/types/listing-types';
import { type UserListingsData } from '@/features/listing/types/user-listings-types';
import SearchPlace from '@/features/place/components/search-place/search-place';
import {
  type CurrentPlace,
  type Place,
} from '@/features/place/types/place-types';
import placeNameToUrl from '@/features/place/utils/place-name-to-url';
import AppBanner from '@/features/roomster-app/components/app-banner/app-banner';
import AppDownload from '@/features/roomster-app/components/app-download/app-download';
import { loggedUserAtom } from '@/features/user/atoms/logged-user-atom';
import { type MessagesCounters } from '@/features/user/types/message-counters-types';
import { type LoggedUser } from '@/features/user/types/user-types';
import { Link } from '@/i18n/routing';
import HeaderLoginedLinks from '@/layout/header-logined-links/header-logined-links';
import Menu from '@/layout/menu/menu';
import { ROUTER_LINKS } from '@/shared/constants/links-constants';
import Button from '@/ui/button/button';

import styles from './header.module.scss';

interface HeaderProps {
  lastSearchedPlaces: Place[] | null;
  serviceType: ListingType | null;
  currentPlace: CurrentPlace | null;
  loggedUserData: LoggedUser | null;
  messagesCounters: MessagesCounters | null;
  helpdeskNewNotificationsCount: number | null;
  userListingsData: UserListingsData | null;
  languages: Language[];
  currencies: CurrenciesList,
  currentCurrency: Currency,
  isHomePage?: boolean,
  isListingsListPage?: boolean,
  isFirstListing?: boolean,
}

export default function Header(props: HeaderProps) {
  const {
    currencies,
    currentCurrency,
    currentPlace,
    helpdeskNewNotificationsCount,
    isFirstListing,
    isHomePage,
    isListingsListPage,
    languages,
    lastSearchedPlaces,
    loggedUserData,
    messagesCounters,
    serviceType,
    userListingsData,
  } = props;

  useHydrateAtoms([[loggedUserAtom, loggedUserData]]);

  const loggedUser = useAtomValue(loggedUserAtom);

  // const isMount = useIsMounted();

  // const [pageUrl,
  //   setPageUrl] = useState<string | null>(null);

  // let isHomePage: boolean | null = null;
  // let isListingsListPage: boolean | null = null;
  //
  // if (pageUrl) {
  //   isHomePage = pageValidators.homePage(pageUrl);
  //   isListingsListPage = pageValidators.listingsListPage(pageUrl);
  // }

  const translationsActions = useTranslations('global.actions');
  const listingTypeVariant = serviceType ? LISTING_URL_PART_VARIANTS[serviceType] : 'haveshare';
  const listingTypeSlug = DEFAULT_LISTING_PART_VARIANTS[listingTypeVariant];
  const placeFullName = currentPlace ? currentPlace.fullName : 'anywhere';
  const loggedUserHomeLink = currentPlace?.fullName ?? placeFullName;

  // useEffect(() => {
  //   const urlUse = window.location.href;
  //   setPageUrl(urlUse);
  // }, [isMount]);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.top_banner}>
          <AppBanner />
        </div>

        <div className={styles.main}>
          {(loggedUser && isListingsListPage) ?? isHomePage ?? isFirstListing ? (
            <div className={cx(styles.logo, (isFirstListing ?? isHomePage) && styles.logoShiftRight)}>
              <Image
                src="/images/roomster-logo/logo.svg"
                width={38}
                height={38}
                alt="roomster logo"
              />
            </div>
          ) : (
          // eslint-disable-next-line canonical/prefer-react-lazy
            <Link
              aria-label="home link"
              className={styles.logo}
              href={loggedUser ? `/${listingTypeSlug}/${placeNameToUrl(loggedUserHomeLink)}` : ROUTER_LINKS.home}
            >
              <Image
                src="/images/roomster-logo/logo.svg"
                width={38}
                height={38}
                alt="roomster logo"
              />
            </Link>
          )}

          {!isFirstListing && !isHomePage && (
            <div className={cx(styles.search)}>
              <SearchPlace currentPlace={currentPlace} lastSearchedPlaces={lastSearchedPlaces} />
            </div>
          )}

          {!isFirstListing && !isHomePage && (
            <div className={styles.app_download}>
              <AppDownload />
            </div>
          )}

          <div className={styles.language_picker}>
            <LanguageSelect options={languages} />
          </div>

          <div className={styles.currency_picker}>
            <CurrencySelect currentCurrency={currentCurrency} currencies={currencies} />
          </div>

          {!isFirstListing && loggedUser && (
            <div className={styles.header_loggedUserLinks}>
              <HeaderLoginedLinks
                messagesCounters={messagesCounters}
                helpdeskNewNotificationsCount={helpdeskNewNotificationsCount}
              />
            </div>
          )}

          {!loggedUser && (
            <div className={styles.login}>
              <Button href={ROUTER_LINKS.phoneAuth} element="link" variant="outlined" size="lg" fullWidth>
                {translationsActions('login')}
              </Button>
            </div>
          )}

          <div className={cx(styles.menu, loggedUser && styles.menu__logined)}>
            <Menu
              loggedUser={loggedUser}
              isFirstListing={isFirstListing}
              messagesCounters={messagesCounters}
              helpdeskNewNotificationsCount={helpdeskNewNotificationsCount}
              userListingsData={userListingsData}
              searchLink={`/${listingTypeSlug}/${placeNameToUrl(placeFullName)}`}
            />
          </div>
        </div>
      </header>
      <div className={styles.header__margin} />
    </>
  );
}
