import { type Coordinates } from '@/features/place/types/place-types';
import { type UserCoordinatesResponse } from '@/features/place/types/user-coordinates-response-types';
import sendHttpError from '@/libs/logger/requests/send-http-error';

import userCoordinatesAdapter from './adapters/user-coordinates-adapter';

export default async function getUserCoordinates(): Promise<Coordinates | null> {
  try {
    const url = `${process.env.NEXT_PUBLIC_API_URL}/v1/ipgeo`;
    const result = await fetch(url);

    if (!result.ok) {
      sendHttpError({
        baseName: 'Get user coordinates',
        functionName: 'getUserCoordinates',
        method: 'GET',
        statusCode: result.status,
        url,
      });
    }

    const responseData = await result.json() as UserCoordinatesResponse;

    return userCoordinatesAdapter(responseData);
  } catch {
    return null;
  }
}
