/* eslint-disable canonical/prefer-react-lazy */

'use client';

import cx from 'classix';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FaAngleRight,
  FaCircleCheck,
  FaCircleExclamation,
  FaCircleXmark,
  FaClock,
  FaLock,
} from 'react-icons/fa6';
import {
  MdClose,
  MdMenu,
} from 'react-icons/md';
import { useOnClickOutside } from 'usehooks-ts';

// import { userListingsAtom } from '@/features/listing/atoms/user-listings-atom';
import { type UserListingsData } from '@/features/listing/types/user-listings-types';
import useAppFlyerLink from '@/features/roomster-app/hooks/use-app-flyer-link';
import { type MessagesCounters } from '@/features/user/types/message-counters-types';
import { VerificationStatus } from '@/features/user/types/user-id-verification-types';
import { type LoggedUser } from '@/features/user/types/user-types';
import {
  Link,
  usePathname,
} from '@/i18n/routing';
import Navigation from '@/layout/menu/navigation/navigation';
import { ROUTER_LINKS } from '@/shared/constants/links-constants';
import Avatar from '@/ui/avatar/avatar';
import Button from '@/ui/button/button';

import styles from './menu.module.scss';

interface MenuProps {
  loggedUser: LoggedUser | null;
  messagesCounters: MessagesCounters | null;
  helpdeskNewNotificationsCount: number | null;
  userListingsData: UserListingsData | null;
  searchLink: string;
  isFirstListing?: boolean;
}

export default function Menu(props: MenuProps) {
  const {
    helpdeskNewNotificationsCount,
    isFirstListing,
    loggedUser,
    messagesCounters,
    searchLink,
    userListingsData,
  } = props;

  const menuRef = useRef(null);

  const pathname = usePathname();

  const translationsActions = useTranslations('global.actions');
  const translationsMenu = useTranslations('layout.menu');
  const translationsVerifications = useTranslations('global.verifications');
  const translationsEmailValidation = useTranslations('page.account-validation.email-validation');

  const loginAppFlyerLink = useAppFlyerLink('mobile', 'mobileLoginButton', ROUTER_LINKS.phoneAuth);

  const [isMobileMenuOpen,
    setMobileMenuOpen] = useState(false);

  const openMenu = () => {
    setMobileMenuOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeMenu = () => {
    setMobileMenuOpen(false);
    document.body.style.overflow = 'unset';
  };

  useOnClickOutside(menuRef, closeMenu);

  useEffect(() => {
    if (!isMobileMenuOpen) {
      document.body.style.overflow = 'unset';
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname]);

  return (
    <>
      {loggedUser ? (
        <button
          className={styles.menu_userButton}
          onClick={openMenu}
        >
          <Avatar imageSrc={loggedUser.avatar.pathSmall} alt={loggedUser.firstName} />
          <div className={styles.menu_userName}>{loggedUser.firstName}</div>
        </button>
      ) : (
        <div className={styles.menu_icon}>
          <MdMenu onClick={openMenu} />
        </div>
      )}

      <div className={cx(styles.menu_background, isMobileMenuOpen && styles.menu_background__isActive)} />

      <div ref={menuRef} className={cx(styles.menu, isMobileMenuOpen && styles.menu__isActive)}>
        <div className={cx(styles.menu_icon, styles.close_menu)}>
          <MdClose onClick={closeMenu} />
        </div>

        <div className={styles.logo_wrapper}>
          <Link className={styles.logo} href={ROUTER_LINKS.home}>
            <Image src="/images/roomster-logo/logo.svg" width={36} height={36} alt="roomster logo" />
          </Link>
        </div>

        {!isFirstListing && (
          <>
            {!loggedUser && (
              <div className={styles.login}>
                <Button
                  href={loginAppFlyerLink}
                  element="link"
                  passHref
                  target="_blank"
                  shade="dark"
                  variant="filled"
                  size="md"
                  fullWidth
                >
                  {translationsActions('login')}
                </Button>
              </div>
            )}

            {loggedUser && (
              <>
                {!loggedUser.isPaidAccount && (
                  <Link href={ROUTER_LINKS.choosePackage} className={cx(styles.menu_userInfoBlock, styles.upgradeAccount)}>
                    <div className={styles.upgradeAccount_avatar}>
                      <Avatar imageSrc={loggedUser.avatar.pathSmall} alt={loggedUser.firstName} />
                      <div className={styles.upgradeAccount_avatarIcon}>
                        <FaLock className={styles.upgradeAccount_avatarIconSvg} />
                      </div>
                    </div>

                    <div>
                      <div className={styles.upgradeAccount_title}>{translationsMenu('upgrade')}</div>
                      <div className={styles.upgradeAccount_description}>{translationsMenu('unlock-mailbox')}</div>
                    </div>

                    <FaAngleRight className={styles.menu_arrowIcon} />
                  </Link>
                )}

                <Link href={ROUTER_LINKS.accountValidation} className={cx(styles.menu_userInfoBlock, styles.userVerifications)}>
                  <Avatar imageSrc={loggedUser.avatar.pathSmall} alt={loggedUser.firstName} />

                  <div className={styles.userVerifications_list}>
                    <div className={styles.userVerifications_item}>
                      <p>{translationsVerifications('phone-validated')}</p>
                      <FaCircleCheck className={cx(styles.userVerifications_icon, loggedUser.verifications.phone && styles.userVerifications_icon__isChecked)} />
                    </div>

                    <div className={styles.userVerifications_item}>
                      <p>{loggedUser.verifications.email ? translationsVerifications('email-validated') : translationsEmailValidation('title')}</p>

                      {loggedUser.verifications.email ? (
                        <FaCircleCheck className={cx(styles.userVerifications_icon, styles.userVerifications_icon__isChecked)} />
                      ) : (
                        <span style={{ width: '18px' }} />
                      )}
                    </div>

                    {loggedUser.verifications.email && loggedUser.verifications.id && (
                      <div className={styles.userVerifications_item}>
                        {loggedUser.verifications.id.status === VerificationStatus.New && (
                          <>
                            <p>{translationsVerifications('get-id-check')}</p>
                            <span style={{ width: '18px' }} />
                          </>
                        )}

                        {loggedUser.verifications.id.status === VerificationStatus.Passed && (
                          <>
                            <p>{translationsVerifications('id-check-passed')}</p>
                            <FaCircleCheck
                              className={cx(
                                styles.userVerifications_icon,
                                styles.userVerifications_icon__isChecked,
                              )}
                            />
                          </>
                        )}

                        {loggedUser.verifications.id.status === VerificationStatus.Rejected && (
                          <>
                            <p>{translationsVerifications('id-check-rejected')}</p>
                            <FaCircleXmark
                              className={cx(
                                styles.userVerifications_icon,
                                styles.userVerifications_icon__isRejected,
                              )}
                            />
                          </>
                        )}

                        {loggedUser.verifications.id.status === VerificationStatus.Error && (
                          <>
                            <p>{translationsVerifications('id-check-error')}</p>
                            <FaCircleExclamation
                              className={cx(
                                styles.userVerifications_icon,
                                styles.userVerifications_icon__isError,
                              )}
                            />
                          </>
                        )}

                        {loggedUser.verifications.id.status === VerificationStatus.Pending && (
                          <>
                            <p>{translationsVerifications('id-check-pending')}</p>
                            <FaClock
                              className={cx(
                                styles.userVerifications_icon,
                                styles.userVerifications_icon__isPending,
                              )}
                            />
                          </>
                        )}
                      </div>
                    )}

                    {loggedUser.verifications.email && userListingsData?.isAddressVerificationShow && (
                      <div className={styles.userVerifications_item}>
                        <p>{translationsVerifications('get-address-check')}</p>
                        <span style={{ width: '18px' }} />

                        {/* {(!userListingsData.listings[0].verificationDetails || userListingsData.listings[0].verificationDetails.status === VerificationStatus.New) && ( */}
                        {/*   <> */}
                        {/*     <p>{translationsVerifications('get-address-check')}</p> */}
                        {/*     <span style={{ width: '18px' }} /> */}
                        {/*   </> */}
                        {/* )} */}

                        {/* {userListingsData.listings[0].verificationDetails?.status === VerificationStatus.Passed && ( */}
                        {/*   <> */}
                        {/*     <p>{translationsVerifications('address-check-passed')}</p> */}
                        {/*     <FaCircleCheck */}
                        {/*       className={cx( */}
                        {/*         styles.userVerifications_icon, */}
                        {/*         styles.userVerifications_icon__isChecked, */}
                        {/*       )} */}
                        {/*     /> */}
                        {/*   </> */}
                        {/* )} */}

                        {/* {userListingsData.listings[0].verificationDetails?.status === VerificationStatus.Rejected && ( */}
                        {/*   <> */}
                        {/*     <p>{translationsVerifications('id-check-rejected')}</p> */}
                        {/*     <FaCircleXmark */}
                        {/*       className={cx( */}
                        {/*         styles.userVerifications_icon, */}
                        {/*         styles.userVerifications_icon__isRejected, */}
                        {/*       )} */}
                        {/*     /> */}
                        {/*   </> */}
                        {/* )} */}

                        {/* {userListingsData.listings[0].verificationDetails?.status === VerificationStatus.Error && ( */}
                        {/*   <> */}
                        {/*     <p>{translationsVerifications('id-check-error')}</p> */}
                        {/*     <FaCircleExclamation */}
                        {/*       className={cx( */}
                        {/*         styles.userVerifications_icon, */}
                        {/*         styles.userVerifications_icon__isError, */}
                        {/*       )} */}
                        {/*     /> */}
                        {/*   </> */}
                        {/* )} */}

                        {/* {userListingsData.listings[0].verificationDetails?.status === VerificationStatus.Pending && ( */}
                        {/*   <> */}
                        {/*     <p>{translationsVerifications('id-check-pending')}</p> */}
                        {/*     <FaClock */}
                        {/*       className={cx( */}
                        {/*         styles.userVerifications_icon, */}
                        {/*         styles.userVerifications_icon__isPending, */}
                        {/*       )} */}
                        {/*     /> */}
                        {/*   </> */}
                        {/* )} */}
                      </div>
                    )}
                  </div>

                  <FaAngleRight className={styles.menu_arrowIcon} />
                </Link>
              </>
            )}
          </>
        )}

        <Navigation
          user={loggedUser}
          messagesCounters={messagesCounters}
          helpdeskNewNotificationsCount={helpdeskNewNotificationsCount}
          userListingsData={userListingsData}
          closeMenu={closeMenu}
          searchLink={searchLink}
          isFirstListing={isFirstListing}
        />
      </div>
    </>
  );
}
